<template>
	<div class="live_count">
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="liveCountTableConf" :showOperation="false" :dataOrigin="dataOrigin">
			<template #operation="scope">
				<el-popover
					placement="bottom"
					title=""
					width="400"
					trigger="click">
					<div class="tc">
						<el-input disabled v-model="copyUrl"></el-input>
						<el-button class="mt20" @click="copyActive">点击复制</el-button>
					</div>
					<el-button slot="reference" @click="fz(scope.rowData.id,$event)">立即邀请</el-button>
				</el-popover>
				<el-button class="ml10" @click="$router.push({path: '/liveDetail', query:{id: scope.rowData.id}})">进入待办</el-button>
			</template>
		</e-table>
		<e-dialog title="评级" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {liveCountTableConf} from '@data/liveCount'
import copy from "../../common/copy";
export default {
	name: 'LiveCount',
	data() {
		return {
			liveCountTableConf,
			dataOrigin: {
				data: []
			},
			activeName: "first",
			copyUrl: '',
			total: 1,
			currentPage: 1,
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetZbLists()
		},
		handleClick() {},
		copyActive(e){
			copy(this.copyUrl, e)
		},
		fz(id,e){
			console.log(e)
			let that = this
			that.copyUrl = ''
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.zb.invite.url.handle',{
				id:id}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.copyUrl = res.data.origin
				// copy(res.data.url, e)
				loading.close()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
				loading.close()
			});
		},
		GetZbLists(){
			let that = this
			this.dataOrigin.data = []
			// console.log(that.dataOrigin)
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.zb.time.lists.get',{
				page:this.currentPage,
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.loading = false
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.initData()
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
			this.GetZbLists()
		});
	}
}
</script>